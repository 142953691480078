<template>
    <AppIcon v-bind="$props">
        <!-- eslint-disable @stylistic/max-len -->
        <rect
            x="0.0588379"
            y="5.80005"
            width="24"
            height="12.3636"
            rx="2.90909"
            fill="var(--color-primary)"
        />

        <path
            d="M4.42242 14.4183V9.32735H6.56788C7.10606 9.32735 7.54242 9.48735 7.87697 9.80735C8.21636 10.1274 8.38606 10.537 8.38606 11.0364C8.38606 11.5358 8.21636 11.9455 7.87697 12.2655C7.54242 12.5855 7.10606 12.7455 6.56788 12.7455H5.51333V14.4183H4.42242ZM5.51333 11.7274H6.53152C6.7497 11.7274 6.92424 11.6643 7.05515 11.5383C7.19091 11.4074 7.25879 11.2401 7.25879 11.0364C7.25879 10.8328 7.19091 10.668 7.05515 10.5419C6.92424 10.411 6.7497 10.3455 6.53152 10.3455H5.51333V11.7274Z"
            fill="#000"
        />

        <path
            d="M9.36561 14.4183V9.32735H11.6202C12.1632 9.32735 12.5947 9.48493 12.9147 9.80008C13.2395 10.1104 13.402 10.5225 13.402 11.0364C13.402 11.5358 13.2565 11.931 12.9656 12.2219C12.8444 12.3528 12.6989 12.4546 12.5292 12.5274L13.5838 14.4183H12.3838L11.4747 12.7455H10.4565V14.4183H9.36561ZM10.4565 11.7274H11.5838C11.7874 11.7274 11.9523 11.6643 12.0783 11.5383C12.2092 11.4074 12.2747 11.2401 12.2747 11.0364C12.2747 10.8328 12.2092 10.668 12.0783 10.5419C11.9523 10.411 11.7874 10.3455 11.5838 10.3455H10.4565V11.7274Z"
            fill="#000"
        />

        <path
            d="M15.7662 10.7164C15.451 11.0316 15.2934 11.417 15.2934 11.8728C15.2934 12.3286 15.451 12.7164 15.7662 13.0364C16.0862 13.3516 16.4741 13.5092 16.9298 13.5092C17.3856 13.5092 17.771 13.3516 18.0862 13.0364C18.4062 12.7164 18.5662 12.3286 18.5662 11.8728C18.5662 11.417 18.4062 11.0316 18.0862 10.7164C17.771 10.3964 17.3856 10.2364 16.9298 10.2364C16.4741 10.2364 16.0862 10.3964 15.7662 10.7164ZM14.9589 13.7492C14.4304 13.2304 14.1662 12.6049 14.1662 11.8728C14.1662 11.1407 14.4304 10.5152 14.9589 9.99644C15.4922 9.47766 16.1492 9.21826 16.9298 9.21826C17.7104 9.21826 18.365 9.47766 18.8934 9.99644C19.4268 10.5152 19.6934 11.1407 19.6934 11.8728C19.6934 12.6049 19.4268 13.2304 18.8934 13.7492C18.365 14.268 17.7104 14.5274 16.9298 14.5274C16.1492 14.5274 15.4922 14.268 14.9589 13.7492Z"
            fill="#000"
        />
        <!-- eslint-enable @stylistic/max-len -->
    </AppIcon>
</template>
